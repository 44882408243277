import { ViewPlugin } from '@codemirror/view';
function events(opts) {
  var {
    type = 'scroll',
    events
  } = opts;
  return ViewPlugin.fromClass(class {
    constructor(view) {
      this.dom = void 0;
      this.view = void 0;
      this.view = view;
      if (type === 'dom') {
        this.dom = view.dom;
      } else if (type === 'content') {
        this.dom = view.contentDOM;
      } else {
        this.dom = view.scrollDOM;
      }
      Object.keys(events || {}).forEach(keyname => {
        if (events && events[keyname] && this.dom) {
          this.dom.addEventListener(keyname, events[keyname]);
        }
      });
    }
    destroy() {
      Object.keys(events || {}).forEach(keyname => {
        if (events && events[keyname] && this.dom) {
          this.dom.removeEventListener(keyname, events[keyname]);
        }
      });
    }
  });
}
/**
 * The DOM element that can be styled to scroll.
 * (Note that it may not have been, so you can't assume this is scrollable.)
 */
export function dom(opts) {
  return events({
    type: 'dom',
    events: opts
  });
}
/**
 * The DOM element that wraps the entire editor view.
 */
export function scroll(opts) {
  return events({
    type: 'scroll',
    events: opts
  });
}
/**
 * The editable DOM element holding the editor content.
 * You should not, usually, interact with this content directly though the DOM,
 * since the editor will immediately undo most of the changes you make.
 */
export function content(opts) {
  return events({
    type: 'content',
    events: opts
  });
}